import React from 'react';
import { NumberFormatBase } from "react-number-format";
import { InputSelectOption, ToneVariants } from '../../types';
import { IsoCode2, countryCodeSelection, CountryCodesProps } from '../../data/phonecountrycodes';
import { BeFlag, DeFlag, EuFlag, NlFlag } from '../../icons';
import { Stack } from '../Stack';
import { StackItem } from '../StackItem';
import { InputSelect } from '../InputSelect';
import { InputBase } from '../InputBase';
import { InputGroup } from '../InputGroup';
import { Text } from '../Text';

import * as styles from './InputTelephone.css';

export type InputBaseOwnProps = {
  label: string;
  name: string;
  error?: string;
  value?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>, countryCode: CountryCodesProps | undefined) => void
  onValueChange: (phone: string, countryCode: CountryCodesProps | undefined) => void;
  handlePhoneMasking?: (phone: string, countryCode: CountryCodesProps | undefined) => string
  defaultCountry?: IsoCode2;
} & ToneVariants;

export type TelephoneInputBaseProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'name' | 'className' | 'value' | 'onChange'
> &
  InputBaseOwnProps;

export const InputTelephone = React.forwardRef<HTMLFieldSetElement, TelephoneInputBaseProps>(({
  label,
  name,
  error,
  tone,
  defaultCountry = 'NL',
  onFocus,
  onBlur,
  onValueChange,
  handlePhoneMasking
}) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [dropdownWidth, setDropDownWidth] = React.useState<string>();
  const refPhoneInput = React.useRef<HTMLInputElement>(null);
  const [phoneValue, setPhoneValue] = React.useState<string>('');
  const [countryCode, setCountryCode] = React.useState<CountryCodesProps | undefined>(getCountryCode(defaultCountry));

  const selectOptions: InputSelectOption[] = [
    ...countryCodeSelection.map((country) => {
      return {
        value: country.isoCode2,
        disabled: false,
        display: (
          <Stack direction="row" alignY="center" gap={2}>
            {FlagIcon(country.isoCode2)}
            <Text className={styles.countryLabel}>
              {country.country}
            </Text>
            <span className={styles.countryCode}>
              +{country.countryCodes}
            </span>
          </Stack>
        ),
      };
    }),
    {
      value: '',
      disabled: false,
      display: (
        <Stack direction="row" alignY="center" gap={2}>
          <EuFlag />
          <Text className={styles.countryLabel}>Overige</Text>
        </Stack>
      ),
    },
  ];

  return (
    <InputGroup label={label} error={error} tone={tone}>
      <Stack direction="row" className={styles.InputSelectWrapper}  ref={wrapperRef}>
        <StackItem shrink asChild>
          <InputSelect
            defaultValue={defaultCountry}
            key={defaultCountry}
            label="Selecteer landcode"
            options={selectOptions}
            selectDropDownWidth={dropdownWidth}
            className={styles.InputSelect}
            tone={tone}
            onOpenChange={(open) => {
              if (open && wrapperRef.current) {
                setDropDownWidth(`${wrapperRef.current.offsetWidth}px`);
                return
              }
              
              setDropDownWidth('0px');
            }} 
            onValueChange={(val) => {
              const countryCode = getCountryCode(val);
              setPhoneValue('')
              setCountryCode(countryCode);
            }}
            handleOnCloseOutFocus={(e) => {
              e.preventDefault();
              refPhoneInput.current?.focus();
            }}
          />
        </StackItem>
        <StackItem grow>
           <NumberFormatBase
              aria-live='polite'
              getInputRef={refPhoneInput}
              name={name}
              tone={tone}
              className={styles.InputPhone}
              label="Telefoonnummer"
              customInput={InputBase}
              type="tel"
              inputMode="numeric"
              value={phoneValue}
              onBlur={(e) => onBlur ? onBlur(e, countryCode) : null}
              onFocus={onFocus}
              format={(value) => handlePhoneMasking ? handlePhoneMasking(value, countryCode) : value} 
              onValueChange={(values) => onValueChange(values.formattedValue, countryCode)}
            />
        </StackItem>
      </Stack>
    </InputGroup>
  );
});

const getCountryCode = (isoCode2: string): CountryCodesProps | undefined => {
  const countryPhoneData = countryCodeSelection.find(
    (country) => country.isoCode2 === isoCode2
  );

  return countryPhoneData
};

const FlagIcon = (isoCode2: IsoCode2) => {
  switch (isoCode2) {
    case 'BE':
      return <BeFlag />;
    case 'DE':
      return <DeFlag />;
    case 'NL':
      return <NlFlag />;
    default:
      return <>No icon found</>;
  }
};