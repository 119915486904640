import React from 'react';
import { PostalCodeCheckForm } from '@ads-core/components';
import { PostalCodeCheckFormProps } from '@ads-core/types';
import { useRouter } from 'next/router';
import { postalCodePattern } from '@ads-core/utils';
import { useTrackingContext } from '@liander/context';
import { baseQueryParams } from 'src/utils';

export type CongestionCheckFormProps = {
  congestionCheck?: CongestionCheckProp;
  color?: PostalCodeCheckFormProps['color'];
};
export const CongestionCheckForm = ({ congestionCheck, color }: CongestionCheckFormProps) => {
  const router = useRouter();
  const { trackPostalcodeCheckError } = useTrackingContext();
  const [postalCodeError, setPostalCodeError] = React.useState<string>();

  if (!congestionCheck) return null;

  return (
    <PostalCodeCheckForm
      title={congestionCheck.title.value ?? 'Bekijk de beschikbare capaciteit op uw adres.'}
      postalCode={congestionCheck.postalCode?.value ?? 'Postcode'}
      postalCodeError={postalCodeError}
      submitButton={congestionCheck.button.value ?? 'Controleren'}
      color={color}
      handleOnSubmit={async (data) => {
        const postalCodeIsValid = postalCodePattern.value.test(data.postalCode);

        if (!postalCodeIsValid) {
          const error = postalCodePattern.message;
          trackPostalcodeCheckError({ error });
          setPostalCodeError(error);
          return;
        }

        setPostalCodeError(undefined);
        const params = new URLSearchParams();
        params.set(baseQueryParams.postalCode, data.postalCode.toUpperCase());
        const parsedParams = decodeURIComponent(params.toString());

        router.push(
          {
            pathname: congestionCheck.redirectPage.value.href,
            search: parsedParams,
          },
          undefined,
          { scroll: false }
        );
      }}
      onlyPostalCode
    />
  );
};

export type CongestionCheckProp = {
  redirectPage: {
    value: {
      href: string;
      text: string;
      anchor: string;
      linktype: string;
      class: string;
      target: string;
      querystring: string;
      id: string;
    };
  };
  button: { value: string };
  postalCode: { value: string };
  title: { value: string };
};
