import { getMonth, getYear, isBefore } from 'date-fns';

import {
  IntegrationApiFeaturesCongestionContractsResponsesGetCongestionsResponse as CongestionsResponse,
  IntegrationApiFeaturesCongestionContractsDataDirection as CongestionsDataDirection,
  IntegrationApiFeaturesCongestionContractsDataShortage as CongestionsDataShortage,
  IntegrationApiFeaturesCongestionContractsDataCausativeAsset as CongestionsDataCausativeAsset,
} from '@alliander-fe/api';
import { CongestionCheckerProps } from 'components/CongestionChecker';
import { setReplaceText } from '@alliander-fe/validation';
import { CongestionProps, FeedbackAndConclusionProps } from '.';

// Check the status of the congestion
export const congestion = (color?: string | null) => color === 'Geel' || color === 'Transparant';

// Transforms the date in Quarters
export const getQuarterlyEndDate = (date?: string): QuarterlyEndDateProps | undefined => {
  if (date) {
    const month = getMonth(date) + 1;
    const year = getYear(date);
    const quarter = Math.ceil(month / 3);

    return {
      quarter: quarter,
      year: year,
    };
  }

  return undefined;
};

// Compare the dates and return the latest date.
const setEndNewEndDate = (inputDate: string | undefined | null, latestEndDate?: string): string => {
  if (!inputDate && !latestEndDate) {
    return '';
  }

  if (inputDate && latestEndDate) {
    const savedDate = new Date(latestEndDate);
    const compareDate = new Date(inputDate);

    return isBefore(savedDate, compareDate) ? inputDate : latestEndDate;
  }

  if (latestEndDate) {
    return latestEndDate;
  }

  if (inputDate) {
    return inputDate;
  }

  return '';
};

// Get The latest end date of the unsolved congestions and returns the year and the quarter this month belongs in.
export const getLatestEndDate = (
  congestionData: CongestionProps[]
): {
  latestEndDate: string;
  latestEndDateConsume: string;
  latestEndDateSupply: string;
} => {
  let latestEndDate: string | undefined;
  let latestEndDateConsume: string | undefined;
  let latestEndDateSupply: string | undefined;

  congestionData?.forEach((item) => {
    const consume = congestion(item.level.consume);
    const supply = congestion(item.level.supply);
    const solved = consume && supply;

    // Only checks the unsolved congestions
    if (!solved) {
      latestEndDate = setEndNewEndDate(item.latestEndDate, latestEndDate);
      latestEndDateConsume = setEndNewEndDate(item.latestEndDateConsume, latestEndDateConsume);
      latestEndDateSupply = setEndNewEndDate(item.latestEndDateSupply, latestEndDateSupply);
    }
  });

  const quarterlyEndDate = getQuarterlyEndDate(latestEndDate);
  const quarterlyEndDatConsume = getQuarterlyEndDate(latestEndDateConsume);
  const quarterlyEndDateSupply = getQuarterlyEndDate(latestEndDateSupply);

  return {
    latestEndDate: quarterlyEndDateToString(quarterlyEndDate),
    latestEndDateConsume: quarterlyEndDateToString(quarterlyEndDatConsume),
    latestEndDateSupply: quarterlyEndDateToString(quarterlyEndDateSupply),
  };
};

const quarterlyEndDateToString = (date: QuarterlyEndDateProps | undefined) => {
  return date ? `kwartaal ${date.quarter} van ${date.year}` : '';
};

// Check the causativeAssets and create the data structure for CongestionProps[]. So we can use it to build the blocks.
const getCausativeAssets = (
  causativeAssets: CongestionsDataCausativeAsset[] | undefined | null,
  compareShortage: CongestionsDataShortage | undefined | null,
  direction: 'supply' | 'consume'
): CongestionProps[] => {
  const congestionData: CongestionProps[] = [];

  causativeAssets?.forEach((causativeAsset) => {
    // search for the same asset in the compareShortage
    const sameAsset = compareShortage?.causativeAssets?.find(
      (asset) => asset.name === causativeAsset.name
    );

    // Adds only assets with a endDate or latestEndDate.
    if (causativeAsset.endDate || compareShortage?.latestEndDate) {
      const commonAssetLevel = sameAsset?.level ? sameAsset.level : 'Transparant';
      const causativeAssetLevel = causativeAsset.level ? causativeAsset.level : 'Transparant';
      const latestEndDateConsume =
        direction === 'supply' ? causativeAsset.endDate : sameAsset?.endDate;
      const latestEndDateSupply =
        direction === 'consume' ? causativeAsset.endDate : sameAsset?.endDate;
      let latestEndDate = '';

      if (causativeAsset.endDate && sameAsset?.endDate) {
        const endDateA = new Date(sameAsset.endDate);
        const endDateB = new Date(causativeAsset.endDate);

        latestEndDate = isBefore(endDateA, endDateB) ? sameAsset.endDate : causativeAsset.endDate;
      }

      if (causativeAsset.endDate && !sameAsset?.endDate) {
        latestEndDate = causativeAsset.endDate;
      }

      if (!causativeAsset.endDate && sameAsset?.endDate) {
        latestEndDate = sameAsset?.endDate;
      }

      if (!causativeAsset.endDate && !sameAsset?.endDate) {
        latestEndDate = compareShortage?.latestEndDate ? compareShortage?.latestEndDate : '';
      }

      congestionData.push({
        name: causativeAsset.name ? causativeAsset.name : undefined,
        latestEndDate: latestEndDate,
        latestEndDateConsume: latestEndDateConsume ? latestEndDateConsume : latestEndDate,
        latestEndDateSupply: latestEndDateSupply ? latestEndDateSupply : latestEndDate,
        level: {
          supply: direction === 'supply' ? causativeAssetLevel : commonAssetLevel,
          consume: direction === 'consume' ? causativeAssetLevel : commonAssetLevel,
        },
      });
    }
  });

  return congestionData;
};

// Maps the CongestionsDataDirection to the CongestionProps[].
export const setShortageData = (
  consumeDirection: CongestionsDataDirection | undefined | null,
  supplyDirection: CongestionsDataDirection | undefined | null
): CongestionProps[] => {
  const congestionData: CongestionProps[] = [];

  if (consumeDirection && supplyDirection) {
    // Because the consume and supply always have the same amount of shortages we only had to loop through one of them.
    consumeDirection.shortages?.forEach((consumeShortage) => {
      const supplyShortages = supplyDirection.shortages?.find(
        (asset) => asset.assetName === consumeShortage.assetName
      );

      // Map the causativeAssets and push the data in to one array
      congestionData.push(
        ...getCausativeAssets(consumeShortage.causativeAssets, supplyShortages, 'consume')
      );
      congestionData.push(
        ...getCausativeAssets(supplyShortages?.causativeAssets, consumeShortage, 'supply')
      );
    });
  }

  return congestionData;
};

// Because the CongestionProps[] can contain duplicate data we remove the duplicates based on the latestEndDate.
export const removeDuplicateData = (shortageData: CongestionProps[]) => {
  const singletonData: CongestionProps[] = [];

  shortageData.forEach((shortage) => {
    const duplicates = shortageData.filter((data) => data.name === shortage.name);
    const existInSingletonData = singletonData.find((data) => data.name === shortage.name);

    if (existInSingletonData) return;

    if (duplicates.length !== 1) {
      const reduce = duplicates.reduce((a, b) => {
        return isBefore(new Date(a.latestEndDate), new Date(b.latestEndDate)) ? b : a;
      });

      singletonData.push(reduce);
      return;
    }

    singletonData.push(shortage);
  });

  return singletonData.reverse();
};

// Gets the correct feedback and conclusion
export const getFeedbackAndConclusion = ({
  consume,
  supply,
  replaceText,
  props,
}: {
  props: CongestionCheckerProps['fields'];
  consume: boolean;
  supply: boolean;
  replaceText: { [key: string]: string };
}): FeedbackAndConclusionProps => {
  // feedback no consume and supply
  if (!consume && !supply) {
    return {
      feedback: {
        description: props?.feedbackNoConsumeAndSupplyText?.value
          ? setReplaceText(props?.feedbackNoConsumeAndSupplyText?.value, replaceText)
          : '',
        buttonPrimary: props?.feedbackNoConsumeAndSupplyPrimaryButton?.value,
        buttonSecondary: props?.feedbackNoConsumeAndSupplySecondaryButton?.value,
      },
      conclusion: {
        topText: props?.conclusionNoConsumeAndSupplyUpperText?.value
          ? setReplaceText(props?.conclusionNoConsumeAndSupplyUpperText?.value, replaceText)
          : '',
        bottomText: props?.conclusionNoConsumeAndSupplyLowerText?.value
          ? setReplaceText(props?.conclusionNoConsumeAndSupplyLowerText?.value, replaceText)
          : '',
        firstPrimaryButton: props?.conclusionNoConsumeAndSupplyFirstPrimaryButton?.value,
        firstSecondaryButton: props?.conclusionNoConsumeAndSupplyFirstSecondaryButton?.value,
        secondPrimaryButton: props?.conclusionNoConsumeAndSupplySecondPrimaryButton?.value,
        secondSecondaryButton: props?.conclusionNoConsumeAndSupplySecondSecondaryButton?.value,
      },
    };
  }

  // feedback only consume
  if (consume && !supply) {
    return {
      feedback: {
        description: props?.feedbackOnlyConsumeText?.value
          ? setReplaceText(props?.feedbackOnlyConsumeText?.value, replaceText)
          : '',
        buttonPrimary: props?.feedbackOnlyConsumePrimaryButton?.value,
        buttonSecondary: props?.feedbackOnlyConsumeSecondaryButton?.value,
      },
      conclusion: {
        topText: props?.conclusionOnlyConsumeUpperText?.value
          ? setReplaceText(props?.conclusionOnlyConsumeUpperText?.value, replaceText)
          : '',
        bottomText: props?.conclusionOnlyConsumeLowerText?.value
          ? setReplaceText(props?.conclusionOnlyConsumeLowerText?.value, replaceText)
          : '',
        firstPrimaryButton: props?.conclusionOnlyConsumeFirstPrimaryButton?.value,
        firstSecondaryButton: props?.conclusionOnlyConsumeFirstSecondaryButton?.value,
        secondPrimaryButton: props?.conclusionOnlyConsumeSecondPrimaryButton?.value,
        secondSecondaryButton: props?.conclusionOnlyConsumeSecondSecondaryButton?.value,
      },
    };
  }

  // feedback only supply
  if (!consume && supply) {
    return {
      feedback: {
        description: props?.feedbackOnlySupplyText?.value
          ? setReplaceText(props?.feedbackOnlySupplyText?.value, replaceText)
          : '',
        buttonPrimary: props?.feedbackOnlySupplyPrimaryButton?.value,
        buttonSecondary: props?.feedbackOnlySupplySecondaryButton?.value,
      },
      conclusion: {
        topText: props?.conclusionOnlySupplyUpperText?.value
          ? setReplaceText(props?.conclusionOnlySupplyUpperText?.value, replaceText)
          : '',
        bottomText: props?.conclusionOnlySupplyLowerText?.value
          ? setReplaceText(props?.conclusionOnlySupplyLowerText?.value, replaceText)
          : '',
        firstPrimaryButton: props?.conclusionOnlySupplyFirstPrimaryButton?.value,
        firstSecondaryButton: props?.conclusionOnlySupplyFirstSecondaryButton?.value,
        secondPrimaryButton: props?.conclusionOnlySupplySecondPrimaryButton?.value,
        secondSecondaryButton: props?.conclusionOnlySupplySecondSecondaryButton?.value,
      },
    };
  }

  // feedback no congestion text
  return {
    feedback: {
      description: props?.feedbackNoCongestionText?.value
        ? setReplaceText(props?.feedbackNoCongestionText?.value, replaceText)
        : '',
      buttonPrimary: props?.feedbackNoCongestionPrimaryButton?.value,
      buttonSecondary: props?.feedbackNoCongestionSecondaryButton?.value,
    },
    conclusion: {
      topText: props?.conclusionNoCongestionUpperText?.value
        ? setReplaceText(props?.conclusionNoCongestionUpperText?.value, replaceText)
        : '',
      bottomText: props?.conclusionNoCongestionLowerText?.value
        ? setReplaceText(props?.conclusionNoCongestionLowerText?.value, replaceText)
        : '',
      firstPrimaryButton: props?.conclusionNoCongestionFirstPrimaryButton?.value,
      firstSecondaryButton: props?.conclusionNoCongestionFirstSecondaryButton?.value,
      secondPrimaryButton: props?.conclusionNoCongestionSecondPrimaryButton?.value,
      secondSecondaryButton: props?.conclusionNoCongestionSecondSecondaryButton?.value,
    },
  };
};

export const hasCongestions = (data: undefined | CongestionsResponse) => {
  const consumeCongestion = data?.directions?.consume
    ? congestion(data.directions.consume?.highestLevel)
    : false;
  const supplyCongestion = data?.directions?.supply
    ? congestion(data.directions.supply?.highestLevel)
    : false;

  return {
    consume: consumeCongestion,
    supply: supplyCongestion,
  };
};

type QuarterlyEndDateProps = {
  quarter: number;
  year: number;
};
