import React from 'react';

import { Box, Loader, Stack } from '@ads-core/components';

import { useQuery } from '@tanstack/react-query';
import * as styles from './PolygonMap.css';

type GeoCodeArcgisApi = {
  spatialReference: {
    wkid: number;
    latestWkid: number;
  };
  candidates: {
    address: string;
    location: {
      x: number;
      y: number;
    };
    score: number;
    attributes: {
      StAdd?: string;
      Postal?: string;
    };
    extent: {
      xmin: number;
      ymin: number;
      xmax: number;
      ymax: number;
    };
  }[];
};

const getGeoCodeArcgisApi = async (postalCode: string): Promise<GeoCodeArcgisApi | undefined> => {
  try {
    const uri = `/api/GeocodeServer/findAddressCandidates?f=json&singleLine=${postalCode}&outFields=StAddr,%20Postal&Country=NL&maxLocations=1&returnGeometry=true`;
    const response = await fetch(uri);

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(response.statusText);
    }
  } catch (e) {
    console.error('Rest api call for Arcgis failed:', e);

    return undefined;
  }
};

// Import the function to set the Arcgis Map.
async function loadMap(
  container: HTMLDivElement,
  rings: number[][][] | undefined,
  geoLocation: number[] | undefined
) {
  const { initializeArcgisMap } = await import('./initializeArcgisMap');
  return initializeArcgisMap(container, rings, geoLocation);
}

type PolygonMapProps = {
  isLoading?: boolean;
  postalCode?: string;
  rings?: number[][][];
};

export const PolygonMap = ({ postalCode, rings, isLoading }: PolygonMapProps) => {
  const mapRef = React.useRef<HTMLDivElement>(null);
  const id = React.useId();

  const query = useQuery({
    enabled: !!postalCode,
    queryKey: [postalCode, rings],
    queryFn: () => getGeoCodeArcgisApi(postalCode as string),
  });

  React.useEffect(() => {
    if (!isLoading && mapRef.current) {
      let geoLocation: number[] | undefined;

      if (
        query.data &&
        query.data.candidates.length &&
        query.data.candidates[0].attributes.Postal?.replaceAll(' ', '') === postalCode
      ) {
        const location = query.data.candidates[0].location;
        geoLocation = [location.x, location.y];
      }

      loadMap(mapRef.current, rings, geoLocation);
    }
  }, [mapRef, isLoading, rings, postalCode, query.data]);

  return (
    <Box
      overflow="hidden"
      width="100%"
      height="100%"
      bg="backgroundBackdrop"
      className={styles.polygonMap}
    >
      {isLoading ? (
        <Stack isFullHeight isFullWidth alignY="center" alignX="center">
          <Loader />
        </Stack>
      ) : null}
      <div id={`map-${id}`} ref={mapRef} style={{ width: '100%', height: '100%' }} />
    </Box>
  );
};
