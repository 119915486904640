import React from 'react';
import {
  Box,
  Stack,
  Heading,
  ProgressDetails,
  TextLink,
  Dialog,
  RichText,
} from '@ads-core/components';
import { ChevronRightIcon } from '@ads-core/icons';
import { FilteredOutageHeroProps } from 'components/OutageHero';
import { setReplaceText } from 'src/utils/format';
import { useDialogContext } from '@ads-core/hooks';
import { IntegrationApiFeaturesOutagesContractsDataOutage } from '@alliander-fe/api';
import { OutageDialogContent } from '../OutageModal';

type OutageHeroProps = {
  title: string;
  modalTitle: string;
} & FilteredOutageHeroProps &
  IntegrationApiFeaturesOutagesContractsDataOutage;

export const OutageHeroProgress = (outageData: OutageHeroProps) => {
  const { isOpen, openDialog } = useDialogContext();
  const [dialogIsOpen, setDialogIsOpen] = React.useState<boolean>(false);

  const { energyType, status: apiStatus, cause: apiCause, title, modalTitle, ...rest } = outageData;

  const statusObject = setStatus({
    status: apiStatus,
    cause: getCause(apiCause),
    ...rest,
  });

  const status = statusObject.label;
  const statusDescription = statusObject.description;
  const statusPhases = statusObject.phases;

  const affectedPostalCodes = rest.affectedPostalCodes
    ? setReplaceText(rest.affectedPostalCodes, { ';': ', ' })
    : '';

  const affectedStreets = rest.affectedStreets
    ? setReplaceText(rest.affectedStreets, { ';': ', ' })
    : '';

  React.useEffect(() => {
    if (!isOpen && dialogIsOpen) {
      setDialogIsOpen(false);
    }
  }, [isOpen]);

  if (!outageData || (!status && !statusDescription)) return null;

  return (
    <>
      {dialogIsOpen ? (
        <Dialog>
          <OutageDialogContent
            {...rest}
            outageNumber={rest.outageNumber}
            cause={apiCause}
            status={status}
            statusDescription={statusDescription}
            statusPhases={statusPhases}
            title={modalTitle}
            outageDetailsText={{ value: rest.outageDetailsModalText }}
            outageDetailsLinkOne={{ value: rest.outageDetailsModalLinkOne }}
            outageDetailsLinkTwo={{ value: rest.outageDetailsModalLinkTwo }}
            affectedPostalCodes={affectedPostalCodes}
            affectedStreets={affectedStreets}
          />
        </Dialog>
      ) : null}
      <Box
        bg="containerLight"
        paddingTop={{ initial: 10, md: 14 }}
        paddingBottom={{ initial: 8, md: 10 }}
        paddingLeft={{ initial: 10, md: 16 }}
        paddingRight={{ initial: 10, md: 12 }}
        asChild
      >
        <Stack alignX="start" gap={4} alignY="justify" isFullHeight>
          <Box>
            {outageData ? (
              <Box paddingBottom={4} asChild>
                <Heading size="h4" as="h3">
                  {title}
                </Heading>
              </Box>
            ) : null}
            {statusDescription ? <RichText>{statusDescription}</RichText> : null}
            <ProgressDetails currentStatus={status ?? ''} statusOptions={statusPhases} />
          </Box>

          <TextLink afterIcon={<ChevronRightIcon />} asChild>
            <button
              onClick={() => {
                setDialogIsOpen(true);
                openDialog();
              }}
            >
              Bekijk alle details
            </button>
          </TextLink>
        </Stack>
      </Box>
    </>
  );
};

export function getCause(cause?: string | null) {
  switch (cause) {
    case 'Spontane storing':
      return 'Storing';
    default:
      return 'Onderhoud';
  }
}

type Cause = ReturnType<typeof getCause>;

type StatusProps = {
  cause?: Cause;
  status?: string | undefined | null;
} & FilteredOutageHeroProps;

const setStatus = ({ cause, status, ...outageProps }: StatusProps) => {
  if (cause === 'Onderhoud') {
    const maintenancePhases = [
      outageProps.maintenancePhaseOneLabel,
      outageProps.maintenancePhaseTwoLabel,
      outageProps.maintenancePhaseThreeLabel,
    ].filter((val): val is string => val !== undefined);

    switch (status) {
      case 'monteur onderweg':
        return {
          label: outageProps.maintenancePhaseOneLabel,
          description: outageProps.maintenancePhaseOneText,
          phases: maintenancePhases,
        };
      case 'monteur ter plaatse':
        return {
          label: outageProps?.maintenancePhaseTwoLabel,
          description: outageProps?.maintenancePhaseTwoText,
          phases: maintenancePhases,
        };
      default:
        return {
          label: outageProps?.maintenancePhaseThreeLabel,
          description: outageProps?.maintenancePhaseThreeText,
          phases: maintenancePhases,
        };
    }
  }

  const outagePhases = [
    outageProps?.outagePhaseOneLabel,
    outageProps?.outagePhaseTwoLabel,
    outageProps?.outagePhaseThreeLabel,
  ].filter((val): val is string => val !== undefined);

  switch (status) {
    case 'monteur onderweg':
      return {
        label: outageProps?.outagePhaseOneLabel,
        description: outageProps?.outagePhaseOneText,
        phases: outagePhases,
      };
    case 'monteur ter plaatse':
      return {
        label: outageProps?.outagePhaseTwoLabel,
        description: outageProps?.outagePhaseTwoText,
        phases: outagePhases,
      };
    default:
      return {
        label: outageProps?.outagePhaseThreeLabel,
        description: outageProps?.outagePhaseThreeText,
        phases: outagePhases,
      };
  }
};
