import React from 'react';
import { useRouter } from 'next/router';

import { baseQueryParams, forceString } from 'src/utils';

import {
  IntegrationApi,
  IntegrationApiFeaturesOutagesContractsDataOutage as OutageProps,
} from '@alliander-fe/api';

import {
  ListHeroView,
  Stack,
  TextLink,
  Box,
  Dialog,
  StackItem,
  ScrollLinkWrapper,
  Pagination,
} from '@ads-core/components';
import { ChecklistIcon, ChevronRightIcon, PhoneAndroidIcon } from '@ads-core/icons';
import { useDialogContext, useMedia } from '@ads-core/hooks';
import { mq } from '@ads-core/breakpoints';

import { SitecoreImage } from '@alliander-fe/jss-utils';
import { setReplaceText } from '@alliander-fe/validation';

import { OutagesOnLocationProps } from 'components/OutagesOnLocation';
import { capitalizeLocationName } from 'src/utils/format';

import { useMutation, useQuery } from '@tanstack/react-query';
import { OutageDialogContent, OutageDialogContentProps } from '../OutageModal';
import OutageBlock from '../OutageBlock';

export const queryParams = baseQueryParams;

export const LocationOutages = ({
  outageDetailsModalText,
  outageDetailsModalLinkOne,
  outageDetailsModalLinkTwo,
  ...props
}: OutagesOnLocationProps['fields']) => {
  const { isOpen, openDialog } = useDialogContext();
  const router = useRouter();
  const [outageDialogData, setOutageDialogData] = React.useState<OutageDialogContentProps>();

  const showAllResolved = Boolean(props.onlyShowAllResolvedOutages?.value);

  // The limit per page when we show all resolved outages
  const allResolvedLimit = 10;

  /**
   * Flag to only show the [x] amount of most recent results.
   * When both resolvedOutages and allResolvedOutages are true (because contentmanagers made a mistake), resolvedOutages wins
   */
  const showRecentResolved = Boolean(props.onlyShowRecentlyResolvedOutages?.value);
  const recentlyResolvedLimit = 2;

  const limit = showRecentResolved ? recentlyResolvedLimit : allResolvedLimit;
  const currentPageQuery = forceString(router.query[queryParams.page]);
  const pageNumber = Math.max((Number(currentPageQuery) || 0) - 1, 0);

  const path = router.query.path || [];
  const queryLocation = forceString(router.query.plaats) || path[path.length - 1] || null;

  // If showRecentResolved and showAllResolved are both false => fetch unresolved outages
  const fetchResolved = showRecentResolved ? showRecentResolved : showAllResolved;

  /**
   * Query to get the total count of outages on a location for pagination page count
   * This is a separate call because the Liander API is stupid
   */
  const totalCountQuery = useQuery({
    enabled: !!queryLocation && !showRecentResolved,
    queryKey: [queryLocation, showRecentResolved],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetOutagesOnLocation({
        location: queryLocation,
        Resolved: fetchResolved,
        Amount: 0,
        ReturnCountOnly: true,
      })
  });

  const { status, data } = useQuery({
    enabled: !!queryLocation,
    queryKey: [queryLocation, showRecentResolved, limit, pageNumber],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetOutagesOnLocation({
        location: queryLocation,
        Resolved: fetchResolved,
        Amount: limit,
        Offset: pageNumber * limit,
        ReturnCountOnly: false,
      }),
      select: (data) => {
        return {
          ...data,
          outages: data?.outages?.map((outage) => {
            return {
              ...outage,
              energyType: outage.energyType === 'Elektriciteit' ? 'Stroom' : outage.energyType
            }
          })
        }
      }
  });

  const specificOutageMutation = useMutation({
    mutationFn: IntegrationApi.outagesEndpointsGetSpecificOutage,
  });

  React.useEffect(() => {
    if (!isOpen) {
      setOutageDialogData(undefined);
    }
  }, [isOpen]);

  if (!queryLocation || !data || !data.count || data.count === 0 || !data.outages) return null;

  const resultCount = data.count || 0;
  const totalCount = totalCountQuery.data?.count ?? resultCount;
  const location = capitalizeLocationName(queryLocation);
  const outages = data.outages;
  const amountOfPages = Math.floor(totalCount / limit);
  const currentPage = pageNumber <= amountOfPages ? pageNumber : amountOfPages;

  const handleOpenDialog = async (props: OutageProps) => {
    if (!props.outageNumber) return;

    const outageModalData = await specificOutageMutation.mutateAsync({
      outageNumber: props.outageNumber.toString()
    });

    const hasData = Boolean(outageModalData && outageModalData.outageNumber);

    const energyType = hasData ? outageModalData.energyType : props.energyType
    const transformedEnergyType = energyType === 'Elektriciteit' ? 'Stroom' : energyType

    const dialogData: OutageDialogContentProps = {
      ...(hasData ? outageModalData : props),
      affectedStreets: props.affectedStreets?.replace(/;/g, ', ') || '',
      affectedPostalCodes: props.affectedPostalCodes?.replace(/;/g, ', ') || '',
      energyType: transformedEnergyType,
      outageDetailsText: outageDetailsModalText,
      outageDetailsLinkOne: outageDetailsModalLinkOne,
      outageDetailsLinkTwo: outageDetailsModalLinkTwo,
    };

    if (hasData) {
      dialogData.statusDescription = '';
      dialogData.postalCode = '';
    }

    setOutageDialogData(dialogData);
    openDialog();
  };

  return (
    <>
      {outageDialogData ? (
        <Dialog>
          <OutageDialogContent
            {...outageDialogData}
            resolvedOutages={showRecentResolved || showAllResolved}
          />
        </Dialog>
      ) : null}
      <ScrollLinkWrapper
        anchorId={
          showRecentResolved || showAllResolved
            ? 'opgelosteonderbrekingen'
            : 'actueleonderbrekingen'
        }
      >
        <ListHeroView
          image={<SitecoreImage field={props.image} editable />}
          variant={showRecentResolved ? 'contained' : 'breakout'}
          text={
            status === 'success'
              ? outagesTitle(
                  totalCount,
                  showRecentResolved || showAllResolved ? props?.title?.value : undefined,
                  location
                )
              : undefined
          }
          list={
            status === 'success' ? (
              <Box width="100%" overflowY="hidden" asChild>
                <Stack
                  direction={showRecentResolved ? { initial: 'row', md: 'column' } : 'column'}
                  gap={4}
                  isFullWidth
                >
                  {outages.map((outage, index) => (
                    <Box
                      width={
                        showRecentResolved ? { initial: '90%', sm: '50%', md: '100%' } : '100%'
                      }
                      key={index}
                      asChild
                    >
                      <StackItem shrink={false}>
                        <OutageBlock.Container>
                          {showRecentResolved ? (
                            <OutageBlock.Resolved
                              {...outage}
                              handleToggleDialog={(props) => handleOpenDialog(props)}
                            />
                          ) : null}

                          {!showRecentResolved && showAllResolved ? (
                            <OutageBlock.AllResolved
                              {...outage}
                              handleToggleDialog={(props) => handleOpenDialog(props)}
                            />
                          ) : null}

                          {!showRecentResolved && !showAllResolved ? (
                            <OutageBlock.Current
                              {...outage}
                              resolvedOutages={showRecentResolved || showAllResolved}
                              handleToggleDialog={(props) => handleOpenDialog(props)}
                            />
                          ) : null}
                        </OutageBlock.Container>
                      </StackItem>
                    </Box>
                  ))}
                </Stack>
              </Box>
            ) : null
          }
          explanation={
            status === 'success' ? (
              <Stack gap={2} alignY="end">
                <ExplanationLinks
                  resolvedOutages={showRecentResolved}
                  hasOutages={Boolean(Number(totalCount))}
                  outageData={props}
                />
              </Stack>
            ) : undefined
          }
        />
      </ScrollLinkWrapper>
      {outages ? (
        <Pagination
          forcePage={currentPage}
          pageCount={totalCount ? totalCount / limit : 0}
          onPageChange={(e) => {
            const selectedPage = e.selected + 1;
            router.push({
              pathname: router.pathname,
              query: {
                ...router.query,
                [queryParams.page]: selectedPage,
              },
            });
          }}
        />
      ) : null}
    </>
  );
};

const ExplanationLinks = ({
  resolvedOutages,
  outageData,
  hasOutages,
}: {
  resolvedOutages?: boolean;
  hasOutages: boolean;
  outageData: OutagesOnLocationProps['fields'];
}) => {
  const isDesktop = useMedia(mq.md);

  if (!hasOutages) {
    return (
      <>
        {outageData.noOutagesLinkOne ? (
          <TextLink
            href={outageData.noOutagesLinkOne.value?.href}
            afterIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData.noOutagesLinkOne?.value?.text}
          </TextLink>
        ) : null}
        {outageData.noOutagesLinkTwo ? (
          <TextLink
            href={outageData.noOutagesLinkTwo.value?.href}
            afterIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData.noOutagesLinkTwo.value?.text}
          </TextLink>
        ) : null}
      </>
    );
  }

  if (resolvedOutages && isDesktop) {
    return (
      <>
        {outageData.linkOne ? (
          <TextLink
            href={outageData?.linkOne?.value?.href}
            beforeIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData?.linkOne?.value?.text}
          </TextLink>
        ) : null}
        {outageData.linkTwo ? (
          <TextLink
            href={outageData?.linkTwo?.value?.href}
            beforeIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData?.linkTwo?.value?.text}
          </TextLink>
        ) : null}
      </>
    );
  }

  if (resolvedOutages && !isDesktop) {
    return (
      <>
        {outageData.linkOne ? (
          <TextLink
            href={outageData?.linkOne?.value?.href}
            afterIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData?.linkOne?.value?.text}
          </TextLink>
        ) : null}
        {outageData.linkTwo ? (
          <TextLink
            href={outageData?.linkTwo?.value?.href}
            afterIcon={<ChevronRightIcon size="sm" />}
          >
            {outageData?.linkTwo?.value?.text}
          </TextLink>
        ) : null}
      </>
    );
  }

  return (
    <>
      {outageData.linkOne ? (
        <TextLink
          href={outageData?.linkOne?.value?.href}
          beforeIcon={
            !resolvedOutages ? <PhoneAndroidIcon size="sm" /> : <ChevronRightIcon size="sm" />
          }
        >
          {outageData?.linkOne?.value?.text}
        </TextLink>
      ) : null}
      {outageData.linkTwo ? (
        <TextLink
          href={outageData?.linkTwo?.value?.href}
          beforeIcon={
            !resolvedOutages ? <ChecklistIcon size="sm" /> : <ChevronRightIcon size="sm" />
          }
        >
          {outageData?.linkTwo?.value?.text}
        </TextLink>
      ) : null}
    </>
  );
};

// Create the replace text for the outages based on the maintenance and outages.
const outagesTitle = (
  outages: number | undefined | null,
  resolvedTitle: string | undefined,
  location: string | undefined
): string => {
  if (resolvedTitle)
    return setReplaceText(resolvedTitle, { '{location}': location ? location : '' });

  switch (outages) {
    case 1:
      return `Er is ${outages} gas- of stroomonderbreking in ${location}`;
    case 0:
      return `Er is geen gas- of stroomonderbreking in ${location}`;
    default:
      return `Er zijn ${outages} gas- of stroomonderbrekingen in ${location}`;
  }
};
