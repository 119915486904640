import React from 'react';
import {
  Box,
  GridItem,
  Heading,
  PageGrid,
  Text,
  Stack,
  StackItem,
  Button,
  InputText,
  InputTelephone,
  Checkbox,
  TextLink,
  ScrollLinkWrapper,
  RichText,
} from '@ads-core/components';
import { IntegrationApi } from '@alliander-fe/api';
import useMedia from 'react-use/lib/useMedia';
import { mq } from '@ads-core/breakpoints';
import { SitecoreImage } from '@alliander-fe/jss-utils';
import { OutagesSMSServiceProps } from 'components/OutagesSMSService';
import { useForm, SubmitHandler } from 'react-hook-form';

import { useRouter } from 'next/router';
import { forceString } from 'src/utils';

import { houseNumberPattern, postalCodePattern, telephonePattern } from '@ads-core/utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TelephoneNumberMasking, applyC4Rules } from '../Form/utils/utils';

import * as styles from './OutageSMSService.css';

type Inputs = {
  houseNumber: number;
  addition: string;
  postalCode: string;
  telephoneNumber: string;
  save: boolean;
};

export const OutagesSMSServiceView = (props: OutagesSMSServiceProps['fields']) => {
  const isDesktop = useMedia(mq.md, true);
  const router = useRouter();

  const getServiceMutation = useMutation({
    mutationFn: IntegrationApi.serviceAvailabilityEndpointsGetServiceAvailability,
  });

  const smsMutation = useMutation({
    mutationFn: IntegrationApi.outagesEndpointsSubscribeSmsService,
  });

  // This component is used in multiple places. These multiple places have multiple ways of having the location in the url
  // TODO: We should be getting the location from a reliable single source, or not depend on this local call to render the component
  const path = router.query.path || [];
  const location = forceString(router.query.plaats) || path[path.length - 1] || null;

  const outagesQuery = useQuery({
    enabled: !!location,
    queryKey: [location, 'outagesLocation'],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetOutagesOnLocation({
        location,
        Resolved: false,
        Amount: 2,
        ReturnCountOnly: false,
      }),
  });

  const methods = useForm({
    mode: 'onTouched',
  });

  const isServiceNotFound =
    getServiceMutation.isSuccess && Boolean(!getServiceMutation.data?.isOperational);
  const isLoading = getServiceMutation.isPending || smsMutation.isPending;
  const hasError = getServiceMutation.isError || smsMutation.isError;
  const isSuccess = getServiceMutation.isSuccess && smsMutation.isSuccess;
  const hasNotification = isServiceNotFound || hasError || isSuccess;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (data.save) {
      // Sets the correct format for postalCode and telephoneNumber.
      const postalCode = data.postalCode.replace(/\s+/g, '');
      const telephoneNumber = data.telephoneNumber.replace('+31 6', '06');

      try {
        const result = await getServiceMutation.mutateAsync({
          ...data,
          postalCode,
        });
        if (!result.isOperational) return;
        await smsMutation.mutateAsync({ ...data, postalCode, telephoneNumber });
      } catch (err) {
        return;
      }
    }
  };

  const hasOutages = !!outagesQuery.data?.count;
  if (!hasOutages) {
    return null;
  }

  const renderNotification = () => {
    if (isServiceNotFound) {
      return (
        <>
          We zien aan uw postcode dat Liander niet uw netbeheerder is. Controleer uw gegevens of
          zoek uw netbeheerder op via{' '}
          <TextLink href="https://www.mijnaansluiting.nl/netbeheerders" target="_blank">
            mijnaansluiting.nl
          </TextLink>
          .
        </>
      );
    }

    if (hasError) {
      return (
        <>
          <Box paddingBottom={4} asChild>
            <Heading size="h5" as="h3">
              {props?.errorMessageTitle?.value}
            </Heading>
          </Box>
          <Text>{props?.errorMessageText?.value}</Text>
        </>
      );
    }

    if (isSuccess) {
      return (
        <>
          <Box paddingBottom={4} asChild>
            <Heading size="h5" as="h3">
              {props?.successMessageTitle?.value}
            </Heading>
          </Box>
          <RichText>{props?.successMessageText?.value}</RichText>
        </>
      );
    }
  };

  return (
    <ScrollLinkWrapper anchorId="smsdienst">
      <PageGrid>
        <GridItem columnEnd="-1" columnStart="1">
          <Box bg="containerPrimary" width="100%" borderRadius="brandXl" overflow="hidden" asChild>
            <Stack direction={{ initial: 'column', md: 'row' }}>
              <StackItem grow asChild>
                <Box
                  width={{ initial: '100%', md: '60%' }}
                  paddingTop={{ initial: 10, md: 20 }}
                  paddingBottom={{ initial: 5, md: 20 }}
                  paddingInline={{ initial: 8, md: 20 }}
                >
                  <Box paddingBottom={{ initial: 3, md: 4 }} asChild>
                    <Heading size="h2" color="onDark">
                      {props?.title?.value}
                    </Heading>
                  </Box>
                  <RichText tone="onDark">{props?.text?.value}</RichText>

                  <Box paddingTop={{ initial: 6, md: 10 }}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <PageGrid style={{ '--gutter': 0 }}>
                        <GridItem columnStart="1" columnEnd={{ initial: '-1', md: '7' }} asChild>
                          <Stack isFullWidth asChild>
                            <Box paddingBottom={{ initial: 2, md: 0 }}>
                              <InputText
                                label="Postcode"
                                tone="onDark"
                                error={
                                  methods.formState?.errors?.postalCode?.message as
                                    | string
                                    | undefined
                                }
                                {...methods.register('postalCode', {
                                  required: {
                                    value: true,
                                    message: 'Dit veld is verplicht',
                                  },
                                  pattern: postalCodePattern,
                                })}
                              />
                            </Box>
                          </Stack>
                        </GridItem>
                        <GridItem
                          columnStart={{ initial: '1', md: '7' }}
                          columnEnd={{ initial: '3', sm: '7', md: '10' }}
                          asChild
                        >
                          <Stack isFullWidth asChild>
                            <Box paddingBottom={{ initial: 2, md: 0 }}>
                              <InputText
                                label="Huisnummer"
                                tone="onDark"
                                inputMode="numeric"
                                error={
                                  methods.formState?.errors?.houseNumber?.message as
                                    | string
                                    | undefined
                                }
                                {...methods.register('houseNumber', {
                                  required: {
                                    value: true,
                                    message: 'Dit veld is verplicht',
                                  },
                                  pattern: houseNumberPattern,
                                })}
                              />
                            </Box>
                          </Stack>
                        </GridItem>
                        <GridItem
                          columnStart={{ initial: '3', sm: '7', md: '10' }}
                          columnEnd="-1"
                          asChild
                        >
                          <Stack isFullWidth>
                            <Box paddingBottom={{ initial: 2, md: 0 }}>
                              <InputText
                                label="Toevoeging"
                                tone="onDark"
                                error={
                                  methods.formState?.errors?.houseNumberAddition?.message as
                                    | string
                                    | undefined
                                }
                                {...methods.register('addition')}
                              />
                            </Box>
                          </Stack>
                        </GridItem>
                        <GridItem columnStart="1" columnEnd="-1" asChild>
                          <Stack isFullWidth>
                            <Box paddingBottom={{ initial: 4, md: 0 }}>
                              <InputTelephone
                                label="Telefoonnummer"
                                tone="onDark"
                                error={
                                  methods.formState?.errors?.telefoonnummer?.message as
                                    | string
                                    | undefined
                                }
                                handlePhoneMasking={TelephoneNumberMasking}
                                onValueChange={(value, countryCode) =>
                                  methods.setValue(
                                    'telephoneNumber',
                                    applyC4Rules(value, countryCode)
                                  )
                                }
                                {...methods.register('telephoneNumber', {
                                  required: {
                                    value: true,
                                    message: 'Dit veld is verplicht',
                                  },
                                  pattern: telephonePattern,
                                })}
                              />
                            </Box>
                          </Stack>
                        </GridItem>
                        <GridItem columnStart="1" columnEnd="-1" asChild>
                          <Stack isFullWidth>
                            <Box paddingBottom={{ initial: 4, md: 0 }}>
                              <Checkbox
                                label="Bewaar mijn gegevens voor toekomstige storingen"
                                tone="onDark"
                                onCheckedChange={(checked) => methods.setValue('save', checked)}
                                error={
                                  methods.formState?.errors?.save?.message as string | undefined
                                }
                                {...methods.register('save', {
                                  required: { value: true, message: 'Dit veld is verplicht' },
                                  validate: {
                                    validationModel: (v) => {
                                      if (typeof v === 'boolean') {
                                        return v;
                                      }

                                      return 'Dit veld is verplicht';
                                    },
                                  },
                                })}
                              />
                            </Box>
                          </Stack>
                        </GridItem>
                        <GridItem>
                          <Stack isFullWidth={{ initial: true, md: false }}>
                            <Button
                              type="submit"
                              variant="secondary"
                              tone="onDark"
                              size="large"
                              isLoading={isLoading}
                            >
                              Ontvang meldingen via SMS
                            </Button>
                          </Stack>
                        </GridItem>
                      </PageGrid>
                    </form>
                  </Box>
                </Box>
              </StackItem>

              {isDesktop ? (
                <StackItem grow asChild>
                  <Box width="40%" height="100%" position="relative">
                    <Box
                      bg={hasNotification ? 'backgroundBackdrop' : undefined}
                      position="absolute"
                      padding={20}
                      zIndex="1"
                      asChild
                    >
                      <Stack isFullHeight isFullWidth alignX="center" alignY="center">
                        <Box
                          bg={hasNotification ? 'backgroundLight' : undefined}
                          padding={10}
                          borderRadius="brandXl"
                        >
                          {renderNotification()}
                        </Box>
                      </Stack>
                    </Box>
                    <Box className={styles.image} asChild>
                      <SitecoreImage field={props.image} editable />
                    </Box>
                  </Box>
                </StackItem>
              ) : (
                <StackItem grow asChild>
                  {hasNotification ? (
                    <Box bg={hasNotification ? 'backgroundBackdrop' : undefined} zIndex="1" asChild>
                      <Stack isFullHeight isFullWidth alignX="center" alignY="center">
                        <Box bg={hasNotification ? 'backgroundLight' : undefined} padding={10}>
                          {renderNotification()}
                        </Box>
                      </Stack>
                    </Box>
                  ) : null}
                </StackItem>
              )}
            </Stack>
          </Box>
        </GridItem>
      </PageGrid>
    </ScrollLinkWrapper>
  );
};
