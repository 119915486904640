import React from 'react';
import {
  GridItem,
  PageGrid,
  Box,
  Heading,
  Stack,
  Loader,
  TextLink,
  Text,
  Columns,
  Divider,
  ScrollLinkWrapper,
  Alert,
} from '@ads-core/components';
import { IntegrationApi } from '@alliander-fe/api';
import { OutagesOverviewProps } from 'components/OutagesOverview';
import { setReplaceText } from 'src/utils';
import { capitalizeLocationName } from 'src/utils/format';

import { mq } from '@ads-core/breakpoints';
import useMedia from 'react-use/lib/useMedia';

import { useQuery } from '@tanstack/react-query';
import * as styles from './OutagesOverview.css';

export const OutagesOverviewView = (props: OutagesOverviewProps['fields']) => {
  const { onlyShowResolvedOutages } = props;
  const showResolvedOutages = onlyShowResolvedOutages?.value;

  const isTablet = useMedia(mq.sm);

  const query = useQuery({
    queryKey: ['outages', showResolvedOutages],
    queryFn: showResolvedOutages
      ? IntegrationApi.outagesEndpointsGetLocationsOfResolvedOutages
      : IntegrationApi.outagesEndpointsGetLocationsOfUnresolvedOutages,
  });

  const fetchStatus = query.status;
  const locations = query.data?.locations || [];
  const totalOutages =
    locations.reduce((acc, location) => acc + (location.amountOfOutages || 0), 0) ?? 0;

  return (
    <ScrollLinkWrapper anchorId="onderbrekingenoverzicht">
      <PageGrid>
        <GridItem
          columnStart={{ initial: '1', md: '3' }}
          columnEnd={{ initial: '-1', md: '-3' }}
          asChild
        >
          <Alert role="alert" id={'outages-overview'}>
            {fetchStatus === 'error'
              ? `Er is iets fout gegaan en we kunnen momenteel de ${
                  showResolvedOutages ? 'opgeloste storingen' : 'storingen'
                } niet ophalen. Vernieuw de pagina om het opnieuw te proberen of neem contact met ons op.`
              : null}
          </Alert>
        </GridItem>
      </PageGrid>

      {fetchStatus !== 'error' ? (
        <PageGrid>
          <GridItem
            columnStart={{ initial: '1', md: '3' }}
            columnEnd={{ initial: '-1', md: '-3' }}
            asChild
          >
            <Box paddingBottom={{ initial: 2, md: 8 }} asChild>
              <Heading size="h2">
                {setReplaceText(props.title.value, {
                  '{amountOfOutages}': totalOutages.toString(),
                })}
              </Heading>
            </Box>
          </GridItem>
          <GridItem columnStart="1" columnEnd="-1" asChild>
            <Box
              paddingInline={{ initial: 6, md: 16 }}
              paddingTop={{ initial: 8, md: 16 }}
              paddingBottom={{ initial: 4, md: 10 }}
              borderRadius={'brandXl'}
              bg="containerLight"
            >
              {fetchStatus === 'pending' ? (
                <Box paddingBottom={{ initial: 4, md: 10 }} asChild>
                  <Stack alignX="center" alignY="center" isFullHeight isFullWidth>
                    <Loader />
                  </Stack>
                </Box>
              ) : (
                <Box width="100%">
                  <Columns columns={{ initial: 1, sm: 2, lg: 3 }} gap={16}>
                    {locations.map(({ locationName, amountOfOutages }) =>
                      locationName ? (
                        <Box
                          paddingBottom={{ initial: 4, sm: 6 }}
                          overflow="hidden"
                          key={locationName}
                          asChild
                        >
                          <Stack>
                            <Box position="relative" paddingBottom={{ initial: 0, sm: 1 }} asChild>
                              <Stack direction="row" alignX="justify" alignY="end" isFullWidth>
                                <TextLink
                                  href={`${
                                    props.parentOfOutageLocationWildcardPage.value.href
                                  }/${capitalizeLocationName(locationName)}`}
                                  className={styles.outageOverviewItemLink}
                                >
                                  {capitalizeLocationName(locationName)}
                                </TextLink>
                                <Text size="label">
                                  {renderAmountOfOutages({
                                    outages: amountOfOutages,
                                    resolvedOutages: showResolvedOutages,
                                  })}
                                </Text>
                              </Stack>
                            </Box>
                            {isTablet ? <Divider tone="onLight" /> : null}
                          </Stack>
                        </Box>
                      ) : null
                    )}
                  </Columns>
                </Box>
              )}
            </Box>
          </GridItem>
        </PageGrid>
      ) : null}
    </ScrollLinkWrapper>
  );
};

type RenderAmountOfOutagesProps = { outages?: number; resolvedOutages?: boolean };
const renderAmountOfOutages = ({
  outages,
  resolvedOutages,
}: RenderAmountOfOutagesProps): string => {
  if (resolvedOutages) {
    return `(${outages} opgelost)`;
  }

  return outages === 1 ? `(${outages} onderbreking)` : `(${outages} onderbrekingen)`;
};
