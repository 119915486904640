/**
 * @param postalCodes "1066CH" or "1066CH; 7991CA; 7991CB" when multiple
 * @returns "1066" or "1066, 7991" when multiple
 */
export const getPostalCodeAreasNumbers = (postalCodes: undefined | string | null): string => {
  if (!postalCodes) return '';
  const postalCodesArray = postalCodes.split(';');
  const numberSet: Array<string> = [];

  // We strip the the letters form the postal codes and remove the duplicates.
  const singletonNumberSet = postalCodesArray.reduce((acc, postal) => {
    const postalNumber = postal.substring(0, 4)
    const existingNumber = acc?.find(
      (postalCodes) => postalCodes === postalNumber
    );

    if (acc.length && existingNumber) {
      return acc;
    }

    if (acc.length && !existingNumber) {
      return [...acc, postalNumber]
    }

    return [postalNumber]
  }, numberSet)

  return singletonNumberSet ? singletonNumberSet.toString().replaceAll(',', ', ') : '';
};
