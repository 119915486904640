import { IntegrationApi } from '@alliander-fe/api';
import { useParams } from 'src/hooks/useParams';
import { useQuery } from '@tanstack/react-query';
import { OutageView } from './_View';
import { Props } from '.';

export function OutageGeneral(props: Props) {
  const { reference } = useParams();

  const query = useQuery({
    enabled: Boolean(reference),
    queryKey: [reference],
    queryFn: () =>
      IntegrationApi.outagesEndpointsGetSpecificOutage({
        outageNumber: reference,
      }),
      select: (data) => {
        return {
          ...data,
          energyType: data.energyType === 'Elektriciteit' ? 'Stroom' : data.energyType
        }
      }
  });

  if (!query.isFetched) return null;
  if (!query.data) return null;

  // TODO: Dit betreft een oude of opgeloste storing. Moeten we dit niet afvangen met tekst?
  if (!Object.values(query.data).filter(Boolean).length) return null;

  return (
    <OutageView
      {...props}
      outageData={query.data || {}}
      isSuccess={query.isSuccess}
      isLoading={query.isLoading}
    />
  );
}

