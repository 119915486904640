import React from 'react';
import { ButtonProps as SiteCoreButtonProps } from '@alliander-fe/sitecore-types';
import { useEffect } from 'react';
import { ConclusionBlockProps, FollowUpStepProps } from '@ads-core/types';
import {
  FollowUpSteps,
  Stack,
  CongestionMarker,
  Box,
  ConclusionBlock,
  PageGrid,
  GridItem,
  Heading,
  ButtonLink,
  Loader,
  Alert,
  TextLink,
  Text,
  Button,
} from '@ads-core/components';
import { ButtonProps } from '@ads-core/types';
import { scrollToElement } from '@ads-core/utils';
import { useAdsContext } from '@ads-core/providers';
import { CongestionCheckerProps } from 'components/CongestionChecker';
import { useParams } from 'src/hooks/useParams';
import { useQuery } from '@tanstack/react-query';
import { IntegrationApi } from '@alliander-fe/api';
import { formatPostalCode } from 'src/utils/format';
import {
  congestion,
  getFeedbackAndConclusion,
  getLatestEndDate,
  getQuarterlyEndDate,
  hasCongestions,
  removeDuplicateData,
  setShortageData,
} from './utils';

export const CongestionCheck = (props: CongestionCheckerProps['fields']) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const refCongestion = React.useRef<HTMLDivElement>(null);
  const refConclusion = React.useRef<HTMLDivElement>(null);
  const adsContext = useAdsContext();

  const { postalCode } = useParams();
  const displayPostalCode = formatPostalCode(postalCode);

  const query = useQuery({
    enabled: Boolean(postalCode),
    queryKey: [postalCode],
    queryFn: () => IntegrationApi.congestionEndpointsGetCongestions({ PostalCode: postalCode }),
  });

  const status = query.status;
  const consume = query.data?.directions?.consume;
  const supply = query.data?.directions?.supply;
  const isNetworkProvider = Boolean(consume || supply);

  const shortageData = setShortageData(consume, supply);
  const filteredCongestionData = removeDuplicateData(shortageData);
  const congestions = hasCongestions(query.data);

  // Create the step data
  const followUpSteps = setCongestionFollowUpSteps(filteredCongestionData);
  const latestEndDate = getLatestEndDate(filteredCongestionData);

  // Get the feedback and conclusion text
  const feedbackAndConclusion = getFeedbackAndConclusion({
    props: props,
    replaceText: {
      '{postalCode}': displayPostalCode,
      '{congestionAmount}': `${
        followUpSteps.length === 1
          ? `${followUpSteps.length} knelpunt`
          : `${followUpSteps.length} knelpunten`
      }`,
      '{latestEndDate}': latestEndDate.latestEndDate,
      '{latestEndDateConsume}': latestEndDate.latestEndDateConsume,
      '{latestEndDateSupply}': latestEndDate.latestEndDateSupply,
    },
    ...congestions,
  });

  const congestionData = {
    congestionData: followUpSteps,
    congestions: congestions,
    ...feedbackAndConclusion,
  };

  useEffect(() => {
    if (query.isFetched && ref.current) {
      scrollToElement(ref, adsContext.headerHeight);
    }
  }, [adsContext.headerHeight, query.isFetched]);

  const setNotification = () => {
    switch (true) {
      case status === 'error':
        return <>Er is iets fout gegaan. Probeer het opnieuw of vul een andere postcode in.</>;
      case !isNetworkProvider:
        return (
          <>
            We zien aan uw postcode dat Liander niet uw netbeheerder is. Controleer uw gegevens of
            zoek uw netbeheerder op via{' '}
            <TextLink href="https://www.mijnaansluiting.nl/netbeheerders" target="_blank">
              mijnaansluiting.nl
            </TextLink>
            .
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box ref={ref}>
      <PageGrid>
        <GridItem columnStart={{ initial: '1', lg: '3' }} columnEnd={{ initial: '-1', lg: '-3' }}>
          <Alert variant={status === 'error' ? 'error' : 'info'}>{setNotification()}</Alert>
        </GridItem>
      </PageGrid>
      {status === 'pending' ? (
        <PageGrid>
          <GridItem columnStart={{ initial: '1', lg: '3' }} columnEnd={{ initial: '-1', lg: '-3' }}>
            <Heading size="h2">
              {' '}
              Laden... <Loader />
            </Heading>
          </GridItem>
        </PageGrid>
      ) : null}
      {congestionData?.feedback && isNetworkProvider ? (
        <PageGrid>
          <GridItem columnStart={{ initial: '1', lg: '3' }} columnEnd={{ initial: '-1', lg: '-3' }}>
            <Box paddingBottom={16} asChild>
              <Stack gap={6}>
                {congestionData.congestions ? (
                  <Stack direction="row" alignY="center" gap={6}>
                    <CongestionMarker
                      titel="Verbruiken"
                      congestion={congestionData.congestions.consume}
                    />
                    <CongestionMarker
                      titel="Terugleveren"
                      congestion={congestionData.congestions.supply}
                    />
                  </Stack>
                ) : null}

                <Heading size="h3" as="h2">
                  {congestionData.feedback.description}
                </Heading>

                {props?.congestionText?.value && congestionData?.congestionData?.length ? (
                  <Text>{props.congestionText.value}</Text>
                ) : null}

                {congestionData.feedback.buttonPrimary ||
                congestionData.feedback.buttonSecondary ? (
                  <Stack direction={{ initial: 'column', sm: 'row' }} gap={6}>
                    {congestionData.feedback.buttonPrimary ? (
                      <CongestionButton
                        {...congestionData.feedback.buttonPrimary}
                        variant="primary"
                        handleOnClick={(value) =>
                          scrollToElement(
                            value === 'conclusion' ? refConclusion : refCongestion,
                            adsContext.headerHeight
                          )
                        }
                      />
                    ) : null}

                    {congestionData.feedback.buttonSecondary ? (
                      <CongestionButton
                        {...congestionData.feedback.buttonSecondary}
                        variant="ghost"
                        handleOnClick={(value) =>
                          scrollToElement(
                            value === 'conclusion' ? refConclusion : refCongestion,
                            adsContext.headerHeight
                          )
                        }
                      />
                    ) : null}
                  </Stack>
                ) : null}
              </Stack>
            </Box>
          </GridItem>
        </PageGrid>
      ) : null}
      {congestionData?.congestionData?.length && isNetworkProvider ? (
        <Box ref={refCongestion}>
          <FollowUpSteps
            followUpSteps={congestionData.congestionData}
            conclusion={
              congestionData.conclusion ? (
                <Box width="100%" ref={refConclusion}>
                  <ConclusionBlock {...congestionData.conclusion} />
                </Box>
              ) : null
            }
          />
        </Box>
      ) : null}

      {!congestionData?.congestionData?.length &&
      congestionData?.conclusion &&
      isNetworkProvider ? (
        <PageGrid>
          <GridItem columnStart={{ initial: '1', lg: '2' }} columnEnd={{ initial: '-1', lg: '-2' }}>
            <Box width="100%" paddingTop={12} ref={refConclusion}>
              <ConclusionBlock {...congestionData.conclusion} />
            </Box>
          </GridItem>
        </PageGrid>
      ) : null}
    </Box>
  );
};

type CongestionButtonProps = {
  handleOnClick: (anchor: 'congestion' | 'conclusion') => void;
} & SiteCoreButtonProps &
  Pick<ButtonProps, 'variant'>;

// Checks if the button has an anchor, and map it so we can scroll to the correct section.
// Otherwise we just render an Button link.
const CongestionButton = ({ handleOnClick, variant, text, ...button }: CongestionButtonProps) => {
  const anchor =
    button.anchor === 'congestion' || button.anchor === 'conclusion' ? button.anchor : null;

  return anchor ? (
    <Button variant={variant} onClick={() => handleOnClick(anchor)}>
      {text}
    </Button>
  ) : (
    <ButtonLink {...button} variant={variant}>
      {text}
    </ButtonLink>
  );
};

// Generated the data for the follow up step cards.
const setCongestionFollowUpSteps = (data?: CongestionProps[]): FollowUpStepProps[] => {
  const followUpSteps: FollowUpStepProps[] = [];

  // Creates the data for the cards
  data?.forEach((item) => {
    const consume = congestion(item.level.consume);
    const supply = congestion(item.level.supply);
    const endDate = getQuarterlyEndDate(item.latestEndDate);
    const solved = consume && supply;

    // only add unsolved items to the followUpSteps
    if (!solved) {
      const tag = endDate
        ? `Opgelost (verwacht): ${endDate.quarter}e kwartaal ${endDate.year}`
        : 'Datum onbekend';

      followUpSteps.push({
        title: item.name ? `Knelpunt: ${item.name}` : 'Knelpunt zonder projectnaam',
        tag: tag,
        type: solved ? 'checked' : undefined,
      });
    }
  });

  return followUpSteps;
};

export type CongestionProps = {
  latestEndDate: string;
  latestEndDateSupply: string;
  latestEndDateConsume: string;
  name?: string;
  level: {
    supply?: string;
    consume?: string;
  };
};

type CongestionFeedbackProps = {
  description: string;
  buttonPrimary?: SiteCoreButtonProps;
  buttonSecondary?: SiteCoreButtonProps;
};

export type FeedbackAndConclusionProps = {
  feedback: CongestionFeedbackProps;
  conclusion?: ConclusionBlockProps;
};
