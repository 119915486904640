import React from 'react';
import { useState } from 'react';
import { useDialogContext } from '@ads-core/providers';
import { IntegrationApi } from '@alliander-fe/api';
import { DialogWithContent, Loader, PostalCodeCheckForm } from '@ads-core/components';
import { DialogContentProps, PostalCodeCheckFormProps } from '@ads-core/types';
import { postalCodePattern } from '@ads-core/utils';
import { useTrackingContext } from '@liander/context';
import { ButtonProps } from '@alliander-fe/sitecore-types';
import { useRouter } from 'next/router';
import { useMutation } from '@tanstack/react-query';

export type OutageCheckFormProps = {
  outagecheck?: OutageCheckProps;
  color?: PostalCodeCheckFormProps['color'];
};

export const OutageCheckForm = ({ outagecheck, color }: OutageCheckFormProps) => {
  const { isOpen, openDialog } = useDialogContext();
  const { trackPostalcodeCheckSuccess, trackPostalcodeCheckError } = useTrackingContext();
  const [dialogData, setDialogData] = useState<DialogContentProps>({});
  const [postalCodeError, setPostalCodeError] = useState<string>();
  const [houseNumberError, setHouseNumberError] = useState<string>();
  const router = useRouter();

  const redirectNoOutagesPage = outagecheck?.noOutagesPage.value.href;
  const redirectOutagesPage = outagecheck?.outagePage.value.href;

  const tagValue = outagecheck?.tag.value;

  function getTag({
    postalCode,
    houseNumber,
    addition,
  }: {
    postalCode: string;
    houseNumber: number;
    addition?: string | null;
  }) {
    const houseNumberTag = `${houseNumber}${addition && `-${addition}`}`;

    return tagValue !== ''
      ? `${tagValue} <strong>${postalCode}</strong>, nr. <strong>${houseNumberTag}</strong>`
      : `Hoi bewoner op <strong>${postalCode}</strong>, nr. <strong>${houseNumberTag}</strong>`;
  }

  const mutation = useMutation({
    mutationFn: IntegrationApi.serviceAvailabilityEndpointsGetServiceAvailabilityDetails,
    onError: (error, variables) => {
      if (!variables.postalCode || !variables.houseNumber) return;
      // @ts-ignore status not available
      const message = error.status === 404 ? 'Adres niet gevonden.' : 'Er is iets misgegaan.';

      const tag = getTag({
        postalCode: variables.postalCode,
        houseNumber: variables.houseNumber,
        addition: variables.addition,
      });

      trackPostalcodeCheckError({ error: message });

      if (redirectNoOutagesPage && redirectOutagesPage) {
        openDialog();
      }

      setDialogData({
        tag,
        title: message,
      });
    },
    onSuccess: ({ electricityNetwork, address, gasNetwork, outageNumber }, variables) => {
      trackPostalcodeCheckSuccess();

      const { postalCode, houseNumber, addition } = variables;
      if (!outagecheck || !postalCode || !houseNumber) return;

      const tag = getTag({
        postalCode,
        houseNumber,
        addition,
      });

      if (
        electricityNetwork?.isHealthy &&
        electricityNetwork?.isOperational &&
        gasNetwork?.isHealthy &&
        gasNetwork?.isOperational
      ) {
        const { href, text } = outagecheck.noOutageButton.value;
        // No outage, operational electricity and gas

        if (redirectNoOutagesPage) {
          router.push({
            pathname: redirectNoOutagesPage,
            query: {
              postcode: address?.postalCode,
              huisnummer: address?.houseNumber,
              plaats: address?.city?.toLowerCase(),
            },
          });
        }

        return setDialogData({
          tag,
          title: outagecheck.noOutageTitle.value,
          description: outagecheck.noOutageElectricityAndGasText.value,
          button: href && text ? { href, text } : undefined,
        });
      } else if (
        electricityNetwork?.isHealthy &&
        !electricityNetwork?.isOperational &&
        gasNetwork?.isHealthy &&
        !gasNetwork?.isOperational
      ) {
        const { href, text } = outagecheck.noElectricityAndNoGasButton.value;
        // Not operational electricity and gas

        openDialog();

        return setDialogData({
          tag,
          title: outagecheck.noElectricityAndNoGasTitle.value,
          description: outagecheck.noElectricityAndNoGasText.value,
          button: href && text ? { href, text } : undefined,
        });
      } else if (
        electricityNetwork?.isHealthy &&
        electricityNetwork?.isOperational &&
        !gasNetwork?.isOperational &&
        gasNetwork?.isHealthy
      ) {
        const { href, text } = outagecheck.noOutageButton.value;
        // No outage, operational electricity

        openDialog();

        return setDialogData({
          tag,
          title: outagecheck.noOutageTitle.value,
          description: outagecheck.noOutageElectricityAndNoGasText.value,
          button: href && text ? { href, text } : undefined,
        });
      } else if (
        electricityNetwork?.isHealthy &&
        !electricityNetwork?.isOperational &&
        gasNetwork?.isHealthy &&
        gasNetwork?.isOperational
      ) {
        const { href, text } = outagecheck.noOutageButton.value;
        // No outage, operational gas

        openDialog();

        return setDialogData({
          tag,
          title: outagecheck.noOutageTitle.value,
          description: outagecheck.noOutageNoElectricityAndGasText.value,
          button: href && text ? { href, text } : undefined,
        });
      } else if (
        electricityNetwork?.isOperational &&
        !electricityNetwork?.isHealthy &&
        gasNetwork?.isHealthy &&
        gasNetwork?.isOperational
      ) {
        const { href, text } = outagecheck.outageButton.value;
        // Outage electricity, operational electricity and gas

        if (redirectOutagesPage) {
          router.push({
            pathname: redirectOutagesPage,
            query: {
              referentie: outageNumber,
              postcode: postalCode,
              huisnummer: address?.houseNumber,
              plaats: address?.city?.toLowerCase(),
            },
          });
        }

        return setDialogData({
          tag,
          title: outagecheck.outageTitle.value,
          description: outagecheck.outageElectricityAndGasText.value,
          button:
            href && text
              ? {
                  href: `${href}${outageNumber ? `?referentienummer=${outageNumber}` : ''}`,
                  text,
                }
              : undefined,
        });
      } else if (
        electricityNetwork?.isOperational &&
        !electricityNetwork?.isHealthy &&
        !gasNetwork?.isHealthy &&
        gasNetwork?.isOperational
      ) {
        const { href, text } = outagecheck.outageButton.value;
        // Outage gas and electricity, operational electricity and gas

        if (redirectOutagesPage) {
          router.push({
            pathname: redirectOutagesPage,
            query: {
              referentie: outageNumber,
              postcode: postalCode,
              plaats: address?.city?.toLowerCase(),
            },
          });
        }

        return setDialogData({
          tag,
          title: outagecheck.outageTitle.value,
          description: outagecheck.outageElectricityAndGasText.value,
          button:
            href && text
              ? {
                  href: `${href}${outageNumber ? `?referentienummer=${outageNumber}` : ''}`,
                  text,
                }
              : undefined,
        });
      } else if (
        electricityNetwork?.isOperational &&
        !electricityNetwork?.isHealthy &&
        !gasNetwork?.isOperational &&
        gasNetwork?.isHealthy
      ) {
        const { href, text } = outagecheck.outageButton.value;
        // Outage electricity, operational electricity

        if (redirectOutagesPage) {
          router.push({
            pathname: redirectOutagesPage,
            query: {
              referentie: outageNumber,
              postcode: postalCode,
              plaats: address?.city?.toLowerCase(),
            },
          });
        }

        return setDialogData({
          tag,
          title: outagecheck.outageTitle.value,
          description: outagecheck.outageElectricityAndNoGasText.value,
          button:
            href && text
              ? {
                  href: `${href}${outageNumber ? `?referentienummer=${outageNumber}` : ''}`,
                  text,
                }
              : undefined,
        });
      } else if (
        electricityNetwork?.isHealthy &&
        !electricityNetwork?.isOperational &&
        gasNetwork?.isOperational &&
        !gasNetwork?.isHealthy
      ) {
        const { href, text } = outagecheck.outageButton.value;
        // Outage gas, operational gas

        if (redirectOutagesPage) {
          router.push({
            pathname: redirectOutagesPage,
            query: {
              referentie: outageNumber,
              postcode: postalCode,
              plaats: address?.city?.toLowerCase(),
            },
          });
        }

        return setDialogData({
          tag,
          title: outagecheck.outageTitle.value,
          description: outagecheck.outageNoElectricityAndGasText.value,
          button:
            href && text
              ? {
                  href: `${href}${outageNumber ? `?referentienummer=${outageNumber}` : ''}`,
                  text,
                }
              : undefined,
        });
      } else if (
        electricityNetwork?.isHealthy &&
        electricityNetwork?.isOperational &&
        gasNetwork?.isOperational &&
        !gasNetwork?.isHealthy
      ) {
        const { href, text } = outagecheck.outageButton.value;
        // Outage gas, operational electricity and gas

        if (redirectOutagesPage) {
          router.push({
            pathname: redirectOutagesPage,
            query: {
              referentie: outageNumber,
              postcode: postalCode,
              plaats: address?.city?.toLowerCase(),
            },
          });
        }

        return setDialogData({
          tag,
          title: outagecheck.outageTitle.value,
          description: outagecheck.outageNoElectricityAndGasText.value,
          button:
            href && text
              ? {
                  href: `${href}${outageNumber ? `?referentienummer=${outageNumber}` : ''}`,
                  text,
                }
              : undefined,
        });
      }
    },
  });

  React.useEffect(() => {
    if (!isOpen)
      setDialogData({
        title: (
          <>
            Laden... <Loader />
          </>
        ),
      });
  }, [openDialog, isOpen]);

  if (!outagecheck) return null;

  return (
    <>
      <PostalCodeCheckForm
        title={outagecheck.title.value ?? 'Controleer op actuele storingen in de buurt.'}
        postalCode={outagecheck.postcode.value ?? 'Postcode'}
        postalCodeError={postalCodeError}
        houseNumberAddition="Huisnr. en toevoeging"
        houseNumberAdditionError={houseNumberError}
        submitButton={outagecheck.button.value ?? 'Controleren'}
        color={color}
        handleOnSubmit={async (data) => {
          const { houseNumber = '', postalCode = '', addition = '' } = data;
          const redirectNoOutagesPage = outagecheck?.noOutagesPage.value.href;
          const redirectOutagesPage = outagecheck?.outagePage.value.href;
          let hasError = false;

          const postalCodeIsValid = postalCodePattern.value.test(postalCode);

          if (!postalCodeIsValid) {
            const error = postalCodePattern.message;
            hasError = true;
            trackPostalcodeCheckError({ error });
            setPostalCodeError(error);
          } else {
            setPostalCodeError(undefined);
          }

          if (!houseNumber) {
            const error = 'Vul een huisnummer in';
            hasError = true;
            trackPostalcodeCheckError({ error });
            setHouseNumberError(error);
          } else {
            setHouseNumberError(undefined);
          }

          if (hasError) return;

          if (!redirectNoOutagesPage && !redirectOutagesPage) {
            openDialog();
          }

          mutation.mutate({
            postalCode,
            houseNumber: Number(houseNumber),
            addition,
          });
        }}
      />

      <DialogWithContent {...dialogData} />
    </>
  );
};

export type OutageCheckProps = {
  button: { value: string };
  huisnr: { value: string };
  postcode: { value: string };
  title: { value: string };
  noElectricityAndNoGasButton: {
    value: {
      href?: string;
      text?: string;
      linktype?: string;
      url?: string;
      anchor?: string;
      target?: string;
    };
  };
  noElectricityAndNoGasText: { value: string };
  noElectricityAndNoGasTitle: { value: string };
  noOutageButton: {
    value: {
      href?: string;
      text?: string;
      class?: string;
      id?: string;
      querystring?: string;
      anchor?: string;
      target?: string;
      title?: string;
      linktype?: string;
      url?: string;
    };
  };
  noOutageElectricityAndGasText: { value: string };
  noOutageElectricityAndNoGasText: { value: string };
  noOutageNoElectricityAndGasText: { value: string };
  noOutageTitle: { value: string };
  outageButton: {
    value: {
      href?: string;
      text?: string;
      linktype?: string;
      url?: string;
      anchor?: string;
      target?: string;
    };
  };
  outageElectricityAndGasText: { value: string };
  outageElectricityAndNoGasText: { value: string };
  outageNoElectricityAndGasText: { value: string };
  outageTitle: { value: string };
  tag: { value: string };
  noOutagesPage: { value: ButtonProps };
  outagePage: { value: ButtonProps };
};
